import packageInfo from '../package.json';

// Updated before build!
const version = {
  packageName: packageInfo.name,
  packageVersion: packageInfo.version,
  buildVersion: '1.56',
  commitID: 'd83f924',
};

export const sentryRelease =
  ((version.buildVersion !== null && version.buildVersion !== '') ? version.buildVersion : version.packageVersion) +
  +  (version.commitID !== null ? '+'+version.commitID : '');

const exported = {
  version,
  sentryRelease
};

export default exported;
