import styles from './style.module.scss';
import CustomerDetailsBox from 'pages/customer-details/CustomerDetailsBox';
import { ReactComponent as FlagIcon } from 'assets/images/svg/flag.svg';
import RiskScoreOverviewTableRow from './RiskScoreOverviewTableRow';
import { useEffect, useState } from 'react';
import { chunkArray, valueExists } from '../../utils/common';
import { DEBUG_KEY } from '../debug/debug';
import { numberToPrice } from '../../utils/billing/plan-formatter';
import { formatNumber } from '../../utils/number-formatter';

const HIDDEN_KEYS = [
  'first_tx',
  'last_tx',
  'age',
  'total_received',
  'total_sent',
];

const keyToLabel = {
  first_tx: {
    label: 'First seen',
    suffix: ''
  },
  last_tx: {
    label: 'Last seen',
    suffix: ''
  },
  age: {
    label: 'Account age',
    suffix: ' days'
  },
  total_received: {
    label: 'Total received',
    suffix: ''
  },
  total_sent: {
    label: 'Total spent',
    suffix: ''
  },
  tx_count: {
    label: 'All transactions',
    suffix: ''
  },
  received_txs: {
    label: 'All received transactions',
    suffix: ''
  },
  sent_txs: {
    label: 'All spent transactions',
    suffix: ''
  },
  closed_loop: {
    label: 'Closed-loop',
    suffix: ''
  },
  external_onchain_outgoing: {
    label: 'External on-chain outgoing',
    suffix: ''
  },
  external_onchain_incoming: {
    label: 'External on-chain incoming',
    suffix: ''
  },
  non_identified_txs: {
    label: 'Non-identified transactions',
    suffix: ''
  },
  last_location: {
    label: 'Last location',
    suffix: ''
  },
  risk_countries: {
    label: 'Risk countries interaction',
    suffix: ''
  },
  high_risk_tx_count: {
    label: 'High risk transactions',
    suffix: ''
  },
  medium_risk_tx_count: {
    label: 'Medium risk transactions',
    suffix: ''
  },
  low_risk_tx_count: {
    label: 'Low risk transactions',
    suffix: ''
  },
  total_volume: {
    label: 'Total volume',
    suffix: ''
  },
  low_risk_volume: {
    label: 'Low risk volume',
    suffix: ''
  },
  medium_risk_volume: {
    label: 'Medium risk volume',
    suffix: ''
  },
  high_risk_volume: {
    label: 'High risk volume',
    suffix: ''
  },
};

const RiskScoreOverview = ({ customerDetails, closedLoop, currency = 'USD' }) => {
  const NUM_DATA_COLUMNS = 2;
  const [riskScore, setRiskScore] = useState(0);
  const [dataColumns, setDataColumns] = useState([]);
  const debug = localStorage.getItem(DEBUG_KEY) === 'ON';

  useEffect(() => {
    if (customerDetails !== undefined) {
      if (customerDetails?.risk !== undefined) {
        setRiskScore(customerDetails.risk);
      } else {
        setRiskScore(0);
      }
    }
  }, [customerDetails]);

  useEffect(() => {
    if (customerDetails !== undefined) {
      const tableEntries = Object.entries(keyToLabel)
        .filter(([key, _]) => !HIDDEN_KEYS.includes(key))
        .filter(([key, _]) => key in (customerDetails ?? {}) || key in (closedLoop ?? {}))
        .map(([key, labelInfo]) => {
          let nicedValue = `${customerDetails[key]}${labelInfo.suffix}`;
          if (!valueExists(customerDetails?.[key]) && !valueExists(closedLoop?.[key])) {
            nicedValue = 'None';
          } else if (['tx_count', 'low_risk_tx_count', 'medium_risk_tx_count', 'high_risk_tx_count'].includes(key)) {
            if (typeof(customerDetails[key]) === 'number') {
              nicedValue = formatNumber(customerDetails[key]);
            }
          } else if (['first_tx', 'last_tx'].includes(key)) {
            if (typeof(customerDetails[key]) === 'string') {
              nicedValue = customerDetails[key];
            } else {
              nicedValue = new Date(customerDetails[key] * 1000).toISOString().split('.')[0].replace('T', ' ');
            }
          } else if (key === 'age') {
            if (customerDetails[key] > Math.round((new Date() - new Date('01/01/1971')) / (1000 * 3600 * 24))) {
              nicedValue = '-';
            }
          } else if (['total_received', 'total_sent', 'total_volume', 'high_risk_volume', 'medium_risk_volume', 'low_risk_volume'].includes(key)) {
            nicedValue = numberToPrice(customerDetails[key], currency, 2);
          } else if (key === 'closed_loop') {
            if (closedLoop === undefined) {
              nicedValue = '-';
            } else {
              nicedValue = closedLoop.closed_loop === true ? 'True':'False';
            }
          } else if (key === 'external_onchain_outgoing') {
            if (closedLoop === undefined) {
              nicedValue = '-';
            } else {
              nicedValue = closedLoop.external_onchain_outgoing === true ? 'True':'False';
            }
          } else if (key === 'external_onchain_incoming') {
            if (closedLoop === undefined) {
              nicedValue = '-';
            } else {
              nicedValue = closedLoop.external_onchain_incoming === true ? 'True':'False';
            }
          }
          return {
            title: labelInfo.label,
            value: nicedValue,
          };
        });
      const numDataEntries = Object.keys(tableEntries).length;
      const entriesPerColumn = Math.ceil(numDataEntries / NUM_DATA_COLUMNS);
      const columns = chunkArray(tableEntries, entriesPerColumn);
      setDataColumns(columns);
    } else {
      setDataColumns([]);
    }
  }, [customerDetails, closedLoop]);
  
  return (
    <CustomerDetailsBox title='Risk score overview' icon={<FlagIcon/>} riskScore={riskScore}>
      <div className={styles.riskOverview}>
        {dataColumns.map((columnData, index) =>
          <div key={`risk-column-${index}`} className={styles.box}>
            {columnData.map((row, index) => (
              <RiskScoreOverviewTableRow key={index} title={row.title} value={row.value}/>
            ))}
          </div>
        )}
      </div>
      {debug &&
        <pre>
          {JSON.stringify(closedLoop, null, 2)}
        </pre>
      }
    </CustomerDetailsBox>
  );
};

export default RiskScoreOverview;
